import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import format from 'date-fns/format'
import { Link } from 'gatsby'
import pad from '../lib/pad'
import { sortEvents } from '../lib/sort'
import { splitPastFuture } from '../lib/events'

import EventTable from '../components/event_table'
import { ActivityLinkTD, activityNavigate } from '../components/activity_link'

const EventListEventTable = ({ title, events, sort }) => (
  <EventTable
    className="table-hover"
    title={title}
    events={events}
    sort={sort}
    cols={6}
    header={() => (
      <tr>
        <th>Date</th>
        <th>Time</th>
        <th>Event</th>
        <th>Ensemble</th>
        <th>Venue</th>
        <th>City</th>
      </tr>
    )}
    activity={(activity, activity_index) => {
      const {
        date_time,
        event: { title, venue, group },
      } = activity
      const ActivityLinkTDI = ActivityLinkTD(activity.event, activity)
      return (
        <tr
          key={activity.event.id + '.' + activity_index}
          onClick={activityNavigate(activity.event, activity)}
        >
          <ActivityLinkTDI className="nowrap" data={format(date_time, 'dd')} />
          <ActivityLinkTDI
            className="nowrap"
            data={format(date_time, 'hh:mm a')}
          />
          <ActivityLinkTDI data={title} />
          <ActivityLinkTDI data={group.id} />
          <ActivityLinkTDI data={venue.id} />
          <ActivityLinkTDI data={`${venue.city}, ${venue.state}`} />
        </tr>
      )
    }}
  />
)

export default function Template({
  data: {
    site: {
      siteMetadata: { title: siteTitle },
    },
    events: { edges: event_nodes, totalCount },
  },
  pageContext: { skip, limit },
}) {
  const sorted_events = sortEvents(event_nodes.map(({ node: e }) => e))
    .reverse()
    .slice(skip, skip + limit)
    .reverse()

  const { past, future } = splitPastFuture(sorted_events)

  const numPages = Math.ceil(totalCount / limit)
  const currPage = Math.ceil(skip / limit) + 1
  const pages = Array.from(Array(numPages).keys()).map((n) => n + 1)

  return (
    <Layout>
      <Helmet title={`${siteTitle} - Events`} />
      <div id="pageheader">
        <h1>Events</h1>
        <span>Come Listen</span>
      </div>
      <EventListEventTable title="Upcoming Events" events={future} sort="ASC" />
      <EventListEventTable title="Past Events" events={past} sort="DESC" />

      <nav aria-label="Page navigation">
        <ul className="pagination justify-content-center">
          {currPage > 1 && (
            <PageLink
              key="left"
              currPage={currPage}
              page={currPage - 1}
              text="«"
            />
          )}
          {pages.map((p) => (
            <PageLink key={p} currPage={currPage} page={p} />
          ))}
          {currPage < numPages && (
            <PageLink
              key="right"
              currPage={currPage}
              page={currPage + 1}
              text="»"
            />
          )}
        </ul>
      </nav>
    </Layout>
  )
}

const PageLink = ({ currPage, page, text }) => {
  const t = text || page
  const className = `page-item ${currPage === page ? 'active' : ''}`
  return page === 1 ? (
    <li key="right" className={className}>
      <Link to={`/events`} className="page-link">
        {t}
      </Link>
    </li>
  ) : (
    <li key="right" className={className}>
      <Link to={`/events/page${pad(page, 2)}`} className="page-link">
        {t}
      </Link>
    </li>
  )
}

export const pageQuery = graphql`
  # query EventsByPagination($skip: Int!, $limit: Int!) {
  query EventsByPagination {
    events: allEventsYaml(
      # skip: $skip
      # limit: $limit
      filter: { published: { eq: true } }
    ) {
      totalCount
      edges {
        node {
          ...ActivityLinkEvent
          title
          published
          group {
            id
            slug
            website
          }
          venue {
            id
            slug
            city
            state
          }
          activities {
            ...ActivityLinkActivity
            name
            public
            duration
            created_date_time
            date_time
          }
        }
      }
    }
    site: site {
      siteMetadata {
        title
      }
    }
  }
`
